import { createRouter, createWebHashHistory } from 'vue-router'

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import('../pages/home/index.vue'),
      children: [
        {
          path: "/404",
          name: "404",
          component: () => import('../pages/404.vue'),
          meta: {
            title: 'Not Found - In ArtemisAds'
          }
        }
      ],
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import('../pages/admin/index.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "welcome",
          name: "welcome",
          component: () => import('../pages/admin/welcome/index.vue'),
          meta: {
            title: 'Welcome - In ArtemisAds',
          }
        },
        {
          path: "product",
          name: "product",
          component: () => import('../pages/admin/product/index.vue'),
          meta: {
            title: 'Product - In ArtemisAds',
          }
        }
      ],
    },
    // {
    //   path: "/mail",
    //   name: "/mail",
    //   component: () => import('../pages/mail.vue'),
    //   meta: {
    //     title: 'mail - In Rewards',
    //   }
    // },
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import('../pages/signIn/index.vue'),
      meta: {
        title: 'Sign In - In ArtemisAds',
      }
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import('../pages/signUp/index.vue'),
      meta: {
        title: 'Sign Up - In ArtemisAds'
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import('../pages/profile/index.vue'),
      meta: {
        title: 'Profile - In ArtemisAds',
      }
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: () => import('../pages/termsOfService/index.vue'),
      meta: {
        title: 'terms-of-service | ArtemisAds',
      }
    },
  ],
})