<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#835CF2',
      },
    }"
    >
  <router-view></router-view>
</a-config-provider>
</template>

<script>
  import { theme } from 'ant-design-vue';
  export default {
    name: "App",
    components: {},
  };
</script>

<style>
html, body {
  margin: 0;
  min-height: 100vh;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.ant-card-head {
  background-color: #eee !important;
}

</style>